<template>
  <div>
    <div class="loading" :class="{'-isShow':isloading}">
      <div class="loader-inner">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <header
      class="header"
      :class="{'-notSet': !pdfFile01 || !pdfFile02}"
    >
      <div class="header-fileblock">
        <div class="fileUpload -wide">
          <div
            class="-uploader"
            @dragover.prevent
            @drop.prevent="onDropOntoUploader($event, 1)"
          >
            <input
              type="file"
              id="fileupload"
              @change="onFileChange($event, 1)"
              style="display: none"
            />
            <label
              class="button -weak -mid fileArea"
              for="fileupload"
            >
            </label>
            <span
              class="-text"
            >
              <span v-if="pdfFile01"><span class="ellipsis">{{ pdfFile01.name }}を設定中</span><small>最終更新日：{{ formatDate(pdfFile01.lastModifiedDate) }}</small><br>変更する場合は新たにファイルをドラッグ＆ドロップ</span>
              <span v-else>ファイルをドラッグ＆ドロップ</span>
            </span>
          </div>
        </div>
        <div class="fileUpload -wide">
          <div
            class="-uploader"
            @dragover.prevent
            @drop.prevent="onDropOntoUploader($event, 2)"
          >
            <input
              type="file"
              id="fileupload"
              @change="onFileChange($event, 2)"
              style="display: none"
            />
            <label
              class="button -weak -mid fileArea"
              for="fileupload"
            >
            </label>
            <span
              class="-text"
            >
              <span v-if="pdfFile02"><span class="ellipsis">{{ pdfFile02.name }}を設定中</span><small>最終更新日：{{ formatDate(pdfFile02.lastModifiedDate) }}</small><br>変更する場合は新たにファイルをドラッグ＆ドロップ</span>
              <span v-else>ファイルをドラッグ＆ドロップ</span>
            </span>
          </div>
        </div>
      </div>
      <div class="header-menublock">
        <label><input type="checkbox" v-model="noDiffCheck">差分なしを非表示</label>
        <label><input type="checkbox" v-model="diffCheck">あおりチェック</label>
        <label><input type="checkbox" v-model="diffRightLeftCheck">左右チェック</label>
      </div>
    </header>
    <div
      class="content"
      v-if="pdfFile01 && pdfFile02"
    >
      <div class="side-content">
        <div class="side-content__image" v-show="pdfFileArray1.length > 0 && pdfFileArray2.length > 0">
          <template
            v-for="(src, index) in pdfFileArray1"
            :key="index"
          >
            <a
              :href="'#page' + (index + 1)"
              class="side-content__image-item"
              v-if="!noDiffCheck || diffPDFArray[index].diffLength > 0"
              :class="{'-active': activePages == index + 1}"
            >
              <div class="pagenum">{{ index + 1 }} / {{ pdfFileArray1.length }}</div>
              <img :src="src" alt="">
            </a>
          </template>
        </div>
        <div class="side-content__image" v-show="pdfFileArray1.length > 0 && pdfFileArray2.length > 0">
          <template
            v-for="(src, index) in pdfFileArray2"
            :key="index"
          >
            <a
              :href="'#page' + (index + 1)"
              class="side-content__image-item"
              v-if="!noDiffCheck || diffPDFArray[index].diffLength > 0"
              :class="{'-active': activePages == index + 1}"
            >
              <img :src="src" alt="">
            </a>
          </template>
        </div>
      </div>
      <div class="main-content">
        <div class="main-content__image" v-if="!diffCheck && !diffRightLeftCheck" v-show="pdfFileArray1.length > 0 && pdfFileArray2.length > 0">
          <template
            v-for="(src, index) in diffPDFArray"
            :key="index"
            >
            <div
              :id="'page' + (index + 1)"
              :data-page="index + 1"
              class="main-content__image-item"
              v-if="!noDiffCheck || src.diffLength > 0"
            >
              <div
                v-if="src.diffLength == 0"
                style="
                  display: inline-flex;
                  padding: 10px;
                  background: red;
                  border-radius: 4px;
                  color: #ffff;
                  font-size: 14px;
                  line-height: 1;
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                "
              >差分なし</div>
              <div class="pagenum">{{ index + 1 }} / {{ pdfFileArray1.length }}</div>
              <img :src="src.src" alt="Diff">
            </div>
          </template>
        </div>
        <div class="main-content__image" v-if="diffCheck" v-show="pdfFileArray1.length > 0 && pdfFileArray2.length > 0">
          <div v-if="showSwitch">
            <template
              v-for="(src, index) in pdfFileArray1"
              :key="index"
            >
              <div
                :id="'page' + (index + 1)"
                :data-page="index + 1"
                class="main-content__image-item"
                v-if="!noDiffCheck || diffPDFArray[index].diffLength > 0"
              >
                <div class="pagenum">{{ index + 1 }} / {{ pdfFileArray1.length }}</div>
                <img :src="src" alt="">
              </div>
            </template>
          </div>
          <div v-else>
            <template
              v-for="(src, index) in pdfFileArray2"
              :key="index"
            >
              <div
                class="main-content__image-item"
                v-if="!noDiffCheck || diffPDFArray[index].diffLength > 0"
              >
              <div class="pagenum">{{ index + 1 }} / {{ pdfFileArray1.length }}</div>
                <img :src="src" alt="">
              </div>
            </template>
          </div>
        </div>
        <div class="main-content__image" v-if="diffRightLeftCheck" v-show="pdfFileArray1.length > 0 && pdfFileArray2.length > 0">
          <template
            v-for="(src, index) in pdfFileArray1"
            :key="index"
          >
            <div class="pagenum">{{ index + 1 }} / {{ pdfFileArray1.length }}</div>
            <div
              :id="'page' + (index + 1)"
              :data-page="index + 1"
              class="main-content__image-item -flex"
              v-if="!noDiffCheck || diffPDFArray[index].diffLength > 0"
            >
              <div class="main-content__image__row">
                <div class="main-content__image__row-item">
                  <img :src="src" alt="">
                </div>
                <div class="main-content__image__row-item">
                  <img :src="pdfFileArray2[index]" alt="">
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.loading{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, .4);
  position: fixed;
  left: 0;
  top: 0;
  overscroll-behavior: none;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  transition: all .5s;

  &.-isShow {
    opacity: 1;
    visibility: visible;
  }
}
.pagenum {
  display: inline-block;
  font-size: 12px;
  white-space: nowrap;
  color: #fff;
  line-height: 1;
  padding: 5px;
  border-radius: 4px;
  background: rgba(0,0,0,.5);
  position: absolute;
  left: 5px;
  top: 5px;
}

.images{
  display: flex;
  gap: 0 30px;
  justify-content: center;
}

.ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

img[src=""] {
  opacity: 0;
}
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  padding: 0 20px 0 10px;
  background: #ddd;
  font-size: 13px;

  @media screen and (max-width: 1024px) {
    height: 100px;
    flex-direction: column;
    gap: 5px;
    padding: 10px 10px;
    align-items: flex-start;
  }

  &-fileblock {
    display: flex;
    gap: 10px;
    flex: 1;
    max-width: calc(100% - 150px);

    @media screen and (max-width: 1024px) {
      max-width: 100%;
    }

    .fileUpload {
      flex: 1;
      max-width: calc((100% / 2) - 10px);

      @media screen and (max-width: 1024px) {
        max-width: calc(50% - 5px);
      }
    }
  }

  &-menublock {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    @media screen and (max-width: 1024px) {
      flex-direction: row;
      gap: 10px;
    }
  }

  &.-notSet {
    width: 100%;
    height: 100vh;
    height: 100dvh;
    padding: 20px;
    flex-direction: column;
    .header-fileblock {
      // flex-direction: column;
      gap: 20px;
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
    .fileUpload {
      flex: 1;
      max-width: 50%;
    }
    .-uploader {
      width: 100%;
      height: 100%;
      font-size: 16px;
      border: 2px dashed #aaa;
      justify-content: center;
      align-items: center;
    }
    .header-menublock {
      display: none;
    }
  }
}

.-uploader {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 5px 20px;
  border: 1px dashed #aaa;
  font-size: 13px;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    font-size: 11px;
    min-height: 46px;
  }
  .-text {
    display: block;
    overflow: hidden;
  }
  .button {
    margin-bottom: 10px;
  }
  .fileArea::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}


.content {
  display: flex;

  .side-content {
    display: flex;
    width: 25%;
    padding: 8px 0;
    height: calc(100vh - 80px);
    height: calc(100dvh - 80px);
    overflow-y: scroll;
    max-width: 200px;
    flex-shrink: 0;
    position: sticky;
    left: 0;
    top: 0;

    @media screen and (max-width: 1024px) {
      height: calc(100vh - 100px);
      height: calc(100dvh - 100px);
    }

    &__image {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 0 30px;

      &:nth-child(2) {
        margin-left: 5px;
        padding-left: 5px;
        border-left: 1px solid #ddd;
      }

      &-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 20vw;
        max-height: 100px;
        min-height: 100px;

        &.-active {
          background-color: rgba(106, 149, 213, .5);
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        &:nth-child(n+2) {
          border-top: 1px dashed #ddd;
        }
      }
    }
  }

  .main-content {
    flex: 1;
    height: calc(100vh - 80px);
    height: calc(100dvh - 80px);
    overflow-y: scroll;
    position: sticky;
    left: 0;
    top: 0;

    @media screen and (max-width: 1024px) {
      height: calc(100vh - 100px);
      height: calc(100dvh - 100px);
    }

    .pagenum {
      position: sticky;
    }

    &__image {

      &__row {
        display: flex;
        width: 100%;
        height: 100%;
        > * {
          width: 50%;
        }
      }
      &-item {
        position: relative;
        height: calc(100vh - 80px);
        height: calc(100dvh - 80px);

        &.-flex {
          display: flex;
        }

        @media screen and (max-width: 1024px) {
          height: calc(100vh - 100px);
          height: calc(100dvh - 100px);
        }

        img {
          margin-top: -25px;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

.block {
  display: flex;
  aspect-ratio: 1/1;
  height: 100%;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px dashed #999;
  position: relative;
}

img {
  width: 100%;
  height: auto;
  max-width: 100%;
  object-fit: contain;
}
</style>

<style>
body {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
</style>

<script>
import { ref, watch } from 'vue';
import { pdfToPng } from './modules/pdfToPng';
import { compareImages } from './modules/compareImages';

export default {
  setup() {
    const pdfFile01 = ref(null);
    const pdfFileArray1 = ref([]);
    const pdfFile02 = ref(null);
    const pdfFileArray2 = ref([]);
    const diffPDFArray = ref([]);
    const maxpageLength = ref(0);

    const diffCheck = ref(false);
    const diffRightLeftCheck = ref(false);
    const noDiffCheck = ref(false);
    const showSwitch = ref(false);
    const isloading = ref(false);
    const activePages = ref(1);

    // diffCheckを監視,trueになったらdiffRightLeftCheckをfalseにする逆も然り
    watch(diffCheck, (newVal) => {
      if (newVal) {
        diffRightLeftCheck.value = false;
      }
    });
    watch(diffRightLeftCheck, (newVal) => {
      if (newVal) {
        diffCheck.value = false;
      }
    });

    // .side-content内で矢印の上下を入力したときに現在表示しているコンテンツを切り替える
    window.addEventListener('keydown', (e) => {
      let mainContent = document.querySelector('.main-content');
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        if (activePages.value == 1) return;
        activePages.value = activePages.value - 1;
      }
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        if (maxpageLength.value <= activePages.value) return;
        activePages.value = activePages.value + 1;
      }
      // .main-contentを#page="activePages.value"の位置にスクロール
      mainContent.scrollTop = document.querySelector(`#page${activePages.value}`).offsetTop;
    });

    const onDropOntoUploader = (event, fileNumber) => {
      onFileChange(event.dataTransfer.files[0], fileNumber);
    };

    const onFileChange = async (event, fileNumber) => {
      const file = event.target ? event.target.files[0] : event;
      console.log(file)
      if ((pdfFile01.value && fileNumber == 2) || (pdfFile02.value && fileNumber == 1)) {
        isloading.value = true;
      }

      if (file) {
        if (fileNumber === 1) {
          pdfFile01.value = file;
          pdfFileArray1.value = [];
          pdfFileArray1.value = await pdfToPng(file);
        } else {
          pdfFile02.value = file;
          pdfFileArray2.value = [];
          pdfFileArray2.value = await pdfToPng(file);
        }
        if (pdfFileArray1.value.length > 0 && pdfFileArray2.value.length > 0) {
          maxpageLength.value = Math.max(pdfFileArray1.value.length, pdfFileArray2.value.length);
          let diffCount = Math.abs(pdfFileArray1.value.length - pdfFileArray2.value.length);
          if (diffCount != 0) {
            if (pdfFileArray1.value.length > pdfFileArray2.value.length) {
              for (let i = 0; i < diffCount; i++) {
                pdfFileArray2.value.push('')
              }
            } else if (pdfFileArray1.value.length < pdfFileArray2.value.length) {
              for (let i = 0; i < diffCount; i++) {
                pdfFileArray1.value.push('')
              }
            }
          }
          isloading.value = true;
          diffPDFArray.value = [];
          diffPDFArray.value = await compareImages(pdfFileArray1.value, pdfFileArray2.value);
          isloading.value = false;
          scrollOvserber();
        }
      }
    };

    function scrollOvserber() {
      // main-contentのスクロールを監視して現在表示しているコンテンツのdata-pageを取得
      document.querySelector('.main-content').addEventListener('scroll', (e) => {
        const scrollTop = e.target.scrollTop;
        const pageHeight = document.querySelector('.main-content__image-item').clientHeight;
        const page = Math.floor(scrollTop / pageHeight) + 1;
        activePages.value = page;
      });
    }

    const formatDate = (date) => {
      return new Date(date).toLocaleString();
    }

    setInterval(() => {
      showSwitch.value = !showSwitch.value;
    }, 500);

    return {
      isloading,
      activePages,
      diffCheck,
      diffRightLeftCheck,
      noDiffCheck,
      showSwitch,
      pdfFile01,
      pdfFile02,
      pdfFileArray1,
      pdfFileArray2,
      diffPDFArray,
      onFileChange,
      onDropOntoUploader,
      formatDate
    };
  }
};
</script>