import pixelmatch from 'pixelmatch';
import { createImage } from './createImage'; // この関数は後で定義します

export async function compareImages(imageSrc1, imageSrc2) {
  // imageSrc1とimageSrc2の数を比較、多い方を基準にループ
  let loopLength = imageSrc1.length > imageSrc2.length ? imageSrc1.length : imageSrc2.length;
  let diffImageArray = [];
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d', { willReadFrequently: true });
  for (let i = 0; i < loopLength; i++) {
    const defaultOptions = {
      width: 0,
      height: 0
    };
    const img1 = imageSrc1[i] ? await createImage(imageSrc1[i]) : defaultOptions;
    const img2 = imageSrc2[i] ? await createImage(imageSrc2[i]) : defaultOptions;
    canvas.width = img1.width > img2.width ? img1.width : img2.width;
    canvas.height = img1.height > img2.height ? img1.height : img2.height;

    context.clearRect(0, 0, canvas.width, canvas.height);
    if (img1.width > 0) context.drawImage(img1, 0, 0);
    const imgData1 = context.getImageData(0, 0, canvas.width, canvas.height).data;

    if (img2.width > 0) context.drawImage(img2, 0, 0);
    const imgData2 = context.getImageData(0, 0, canvas.width, canvas.height).data;

    const diff = context.createImageData(canvas.width, canvas.height);
    // console.log(pixelmatch(imgData1, imgData2, diff.data, canvas.width, canvas.height, { threshold: 0.1 }));
    let match = pixelmatch(imgData1, imgData2, diff.data, canvas.width, canvas.height, { threshold: 0.1 });

    context.putImageData(diff, 0, 0);
    diffImageArray.push(
      {
        src: canvas.toDataURL('image/png'),
        diffLength: match
      }
    );
  }
  return diffImageArray;
}