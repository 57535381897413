import * as pdfjsLib from 'pdfjs-dist';
pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.js';

export async function pdfToPng(file) {
  // 複数枚の画像を取得する
  const tempImageArray = [];
  const loadingTask = pdfjsLib.getDocument({
    url: URL.createObjectURL(file),
    cMapUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.943/cmaps/",
    cMapPacked: true,
  });
  console.log(loadingTask);
  const pdf = await loadingTask.promise;
  console.log(pdf);

  // 総ページ数を取得
  const numPages = pdf.numPages;
  // ページ数分ループ
  for (let i = 1; i <= numPages; i++) {
    const page = await pdf.getPage(i);
    const viewport = page.getViewport({ scale: 2 });
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    const renderContext = {
      canvasContext: context,
      viewport: viewport
    };

    await page.render(renderContext).promise;
    tempImageArray.push(canvas.toDataURL());
  }

  return tempImageArray;
}